import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useLocation } from 'react-router-dom';

import { sendform } from "../../../static/urls";
import "./css/form.css";

const form = {
  title: "Vi hjälper ditt företag – Hör av dig",
  text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom 24h för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
  name: "Namn",
  company: "Företagsnamn",
  email: "Email",
  number: "Telefonnummer",
  body: "Beskriv din situation",
  newsletter: "Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
  termstext: "Jag har läst och godkänner integritetspolicyn.",
  termsLink: "Läs den här"
};

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const Form = (props) => {
  const csrftoken = getCookie("csrftoken");
  const location = useLocation();
  const currentUrl = location.pathname;
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    number: "",
    body: "",
    newsletter: false,
    terms: false,
    page: currentUrl
  });

  const [errorData, setErrorData] = useState({
    name: false,
    company: false,
    email: false,
    number: false,
    body: false,
    terms: false
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    const updatedFormData = { ...formData, page: currentUrl };

    if (!formData.name) { setErrorData((prev) => ({ ...prev, name: true })); hasError = true; }
    if (!formData.email) { setErrorData((prev) => ({ ...prev, email: true })); hasError = true; }
    if (!formData.number) { setErrorData((prev) => ({ ...prev, number: true })); hasError = true; }
    if (!formData.body) { setErrorData((prev) => ({ ...prev, body: true })); hasError = true; }
    if (!formData.terms) { setErrorData((prev) => ({ ...prev, terms: true })); hasError = true; }

    if (hasError) return;

    fetch(sendform, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      },
      body: JSON.stringify(updatedFormData)
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = props.sprak.tack.url;
        } else {
          console.error("Form submission failed");
        }
        return response.json();
      })
      .catch((error) => console.error("Error submitting form:", error));
  };

  return (
    <div className="form-wrapper" style={{ maxWidth: "1100px", margin: "0 auto", padding: "40px 20px" }}>
      <h2 style={{ textAlign: "center" }}>{form.title}</h2>
      <p style={{ textAlign: "left", maxWidth: "750px", margin: "0 auto 30px" }}>{form.text}</p>

      <form onSubmit={handleSubmit} noValidate className="custom-form">
  <div className="form-grid">
    <TextField
      error={errorData.name}
      helperText={errorData.name ? "Skriv in ditt namn." : ""}
      label="Namn"
      value={formData.name}
      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      variant="standard"
      fullWidth
    />
    <TextField
      error={errorData.email}
      helperText={errorData.email ? "Fyll i din mail." : ""}
      label="Email"
      value={formData.email}
      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      variant="standard"
      fullWidth
    />
    <TextField
      error={errorData.number}
      helperText={errorData.number ? "Fyll i ditt telefonnummer." : ""}
      label="Telefonnummer"
      value={formData.number}
      onChange={(e) => setFormData({ ...formData, number: e.target.value })}
      variant="standard"
      fullWidth
    />
    <TextField
      label="Företagsnamn"
      value={formData.company || ""}
      onChange={(e) => setFormData({ ...formData, company: e.target.value })}
      variant="standard"
      fullWidth
    />
  </div>

  <TextField
    error={errorData.body}
    helperText={errorData.body ? "Skriv ett meddelande." : ""}
    label="Beskriv din situation"
    value={formData.body}
    onChange={(e) => setFormData({ ...formData, body: e.target.value })}
    variant="standard"
    fullWidth
    multiline
    rows={4}
    className="full-width-message"
  />

  <div className="form-options">
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.terms}
          onChange={(e) => setFormData({ ...formData, terms: e.target.checked })}
        />
      }
      label={
        <Typography variant="body2">
          Jag har läst och godkänner integritetspolicyn.{" "}
          <a href={props.sprak.privacy.url} target="_blank" rel="noopener noreferrer">
            Läs den här
          </a>
        </Typography>
      }
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.newsletter}
          onChange={(e) => setFormData({ ...formData, newsletter: e.target.checked })}
        />
      }
      label={
        <Typography variant="body2">
          Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?
        </Typography>
      }
    />
  </div>

  <Button
    type="submit"
    variant="contained"
    className="submit-button"
    endIcon={<ArrowForwardIcon />}
  >
    {props.sprak.skicka}
  </Button>
</form>

    </div>
  );
};

export default Form;